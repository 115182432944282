(function () {
	// Search Form
	// let inputSearchDepartement = document.querySelector(
	// 	'input[name="departement"]'
	// );
	// let inputSearchVille = document.querySelector('input[name="ville"]');
	let inputsSearchZone = document.querySelectorAll('input[name="zone"]');
	let selectTypology = document.querySelector('select[name="typology"]');
	// Newsletter
	let newsletterForm = document.querySelectorAll('.newsletter-form');
	// Programmes / terrains
	let favoriteButton = document.querySelectorAll('.favori');
	let documentsButton = document.querySelectorAll('.zone-plaquette label');
	let detailsButton = document.querySelectorAll('.lot .voir label');
	let detailsPanel = document.querySelector('.aside-detail-panel');
	if (detailsPanel != null && typeof detailsPanel != 'undefined') {
		var modelDetailsHtml = detailsPanel.innerHTML;
	}
	let documentsPanel = document.querySelector('.aside-document-panel');
	let documentForm = document.querySelector('.aside-document-panel .wpcf7');
	let planPanel = document.querySelector('.aside-plan-panel');
	let hiddenInputsArr = document.querySelectorAll('aside input[name*="lot-"]');
	let allPanels = document.querySelectorAll('input[name="panels"]');
	// Utilisateurs
	let loginForm = document.getElementById('user_login');
	let registerForm = document.getElementById('user_registration');
	let resetPasswordForm = document.getElementById('password_reset');
	let infosForm = document.getElementById('form-informations');
	let deleteBtn = document.getElementById('delete-account-btn');
	// Contact
	// let contactButton = document.querySelectorAll('label[for="contact-panel"]');
	// let contactPanel = document.querySelector('.aside-contact-panel');
	let radioInput = document.querySelectorAll('.trigger');

	// Ajax sur le submit Newsletter
	if (newsletterForm != null && typeof newsletterForm != 'undefined') {
		newsletterForm.forEach(function (element, id) {
			element.addEventListener('submit', function (e) {
				e.preventDefault();

				var newsletterInput = element.querySelector('input[type="email"]');
				var newsletterSubmit = element.querySelector('input[type="submit"]');

				newsletterInput.disabled = true;
				newsletterSubmit.disabled = true;

				let request = new XMLHttpRequest();
				request.open(
					'GET',
					`${FunctionsAjax.ajaxurl}?action=ajax_newsletter_send&email=${newsletterInput.value}&security=${FunctionsAjax.security}`,
					true
				);
				request.setRequestHeader(
					'Content-Type',
					'application/x-www-form-urlencoded; charset=UTF-8'
				);

				request.onload = function () {
					newsletterInput.disabled = false;
					newsletterSubmit.disabled = false;
					if (200 === this.status) {
						let rep = JSON.parse(this.response);
						if (rep.type == 'success') {
							// add message success
							newsletterInputContainer.innerHTML =
								'<p><i>Vous êtes bien inscrit à la newsletter !</i></p>';
						}
					} else {
						// add message error
						console.log(
							'fail | status : ' +
								this.status +
								' | response : ' +
								this.response
						);
					}
				};
				request.onerror = function () {
					console.log('connection error');
				};
				request.send();
			});
		});
	}

	// Ajax programme favoris
	if (favoriteButton != null && typeof favoriteButton != 'undefined') {
		favoriteButton.forEach(function (element, id) {
			element.addEventListener('click', function (e) {
				e.preventDefault();
				let idProgramme = element.dataset.id;

				let request = new XMLHttpRequest();
				request.open(
					'GET',
					`${FunctionsAjax.ajaxurl}?action=favorite_programme&programme_id=${idProgramme}`,
					true
				);
				request.setRequestHeader(
					'Content-Type',
					'application/x-www-form-urlencoded; charset=UTF-8'
				);
				request.onload = function () {
					if (200 === this.status) {
						let rep = JSON.parse(this.response);
						if (rep.type == 'success') {
							// add message success
							var icoFavorites = element.dataset.ico;
							if (icoFavorites == 'liked') {
								element.setAttribute('data-ico', 'favorites');
							} else if (icoFavorites == 'favorites') {
								element.setAttribute('data-ico', 'liked');
							}
							if (FunctionsAjax.is_dashboard) {
								let toHide = document.getElementById(
									'favorite-program-' + idProgramme
								);
								let favoriteNumbers =
									document.getElementsByClassName('number-favorites');
								[...favoriteNumbers].forEach((number) => {
									number.innerHTML--;
								});
								toHide.classList.add('delete-favorite');
								window.setTimeout(() => {
									toHide.parentNode.removeChild(toHide);
								}, 400);
							}
						}
					} else {
						// add message error
						console.log(
							'fail | status : ' +
								this.status +
								' | response : ' +
								this.response
						);
					}
				};
				request.onerror = function () {
					console.log('connection error');
				};
				request.send();
			});
		});
	}

	// NO Ajax Panel document d'un programme
	if (
		documentForm !== null &&
		typeof documentForm !== undefined &&
		documentsButton != null &&
		typeof documentsButton != 'undefined'
	) {
		documentsButton.forEach(function (element) {
			element.addEventListener('click', function (e) {
				let programDir = element.getAttribute('data-document-link');
				let documentLabel = element.getAttribute('data-trk-document');

				// Add document values in html panel
				var itemDocumentsBannerHtml =
					documentsPanel.querySelector('.banner-header');
				var modelDocumentsBannerHtml = itemDocumentsBannerHtml.innerHTML;
				var modelDocumentsBannerHtmlTmp = modelDocumentsBannerHtml;

				var itemDocumentsDescriptionHtml = documentsPanel.querySelector(
					'.description-inner-html'
				);
				var modelDocumentsDescriptionHtml =
					itemDocumentsDescriptionHtml.innerHTML;
				var modelDocumentsDescriptionHtmlTmp = modelDocumentsDescriptionHtml;

				var regexp = new RegExp('%' + 'nom' + '%', 'g');

				modelDocumentsBannerHtmlTmp = modelDocumentsBannerHtmlTmp.replace(
					regexp,
					documentLabel
				);
				itemDocumentsBannerHtml.innerHTML = modelDocumentsBannerHtmlTmp;
				modelDocumentsDescriptionHtmlTmp =
					modelDocumentsDescriptionHtmlTmp.replace(regexp, documentLabel);
				itemDocumentsDescriptionHtml.innerHTML =
					modelDocumentsDescriptionHtmlTmp;

				// Add document url in hidden field Form document
				documentsPanel.querySelector('input[name="your-document"]').value =
					programDir;

				setTimeout(function () {
					documentsPanel.classList.remove('notloaded');
					documentsPanel.classList.add('loaded');
				}, 100);

				let multiHtml = [
					,
					itemDocumentsBannerHtml,
					itemDocumentsDescriptionHtml,
				];
				cleanPanelMultiHtml(documentsPanel, documentLabel, multiHtml);
			});
		});
	}

	// Ajax programme détails + programme simulateur
	if (detailsButton != null && typeof detailsButton != 'undefined') {
		detailsButton.forEach(function (element, id) {
			// Populer les infos quand on ouvre le détail d'un lot
			element.addEventListener('click', function (e) {
				let referenceLot = element.dataset.lotreference;
				let idProgramme = element.dataset.programmeid;
				let request = new XMLHttpRequest();

				request.open(
					'GET',
					`${FunctionsAjax.ajaxurl}?action=details_lot&programme_id=${idProgramme}&lot_reference=${referenceLot}`,
					true
				);
				request.setRequestHeader(
					'Content-Type',
					'application/x-www-form-urlencoded; charset=UTF-8'
				);
				request.onload = function () {
					if (200 === this.status) {
						let rep = JSON.parse(this.response);
						if (rep.type == 'success') {
							var modelDetailsHtml = detailsPanel.innerHTML;
							var modelDetailsHtmlTmp = modelDetailsHtml;
							let hasVue, hasPlan;

							for (const [lotkey, lotvalue] of Object.entries(rep.message)) {
								var regexp = new RegExp('%' + lotkey + '%', 'g');
								modelDetailsHtmlTmp = modelDetailsHtmlTmp.replace(
									regexp,
									lotvalue
								);
								//console.log(lotkey + "|" + lotvalue);
								if (lotkey == 'vue_3d' && lotvalue.length > 0) {
									hasVue = true;
								}
								if (lotkey == 'plan' && lotvalue.length > 0) {
									hasPlan = true;
								}
							}

							detailsPanel.innerHTML = modelDetailsHtmlTmp;

							// Get href in direct links from dataset href
							let vueUrl = document.querySelector('[target="vue"]');
							let planUrl = document.querySelector('[target="plan"]');

							if (typeof hasVue !== undefined && vueUrl !== null) {
								vueUrl.href = vueUrl.dataset.href;
							}
							if (typeof hasPlan !== undefined && planUrl !== null) {
								planUrl.href = planUrl.dataset.href;
							}

							// Add vue 3d preview img
							var vue_preview_img =
								detailsPanel.querySelector('[data-hasvue] img');
							if (
								vue_preview_img.getAttribute('data-src') != 'false' &&
								vue_preview_img.getAttribute('data-src') != ''
							) {
								vue_preview_img.setAttribute(
									'src',
									vue_preview_img.getAttribute('data-src')
								);
							}

							// Add plan preview img
							var plan_preview_img =
								detailsPanel.querySelector('[data-hasplan] img');
							if (
								plan_preview_img.getAttribute('data-src') != 'false' &&
								plan_preview_img.getAttribute('data-src') != ''
							) {
								plan_preview_img.setAttribute(
									'src',
									plan_preview_img.getAttribute('data-src')
								);
							}

							// Add plan url in hidden field Form plan
							planPanel.querySelector('input[name="your-plan"]').value =
								rep.message.plan;

							// Add lot-DATAS in hidden field Form contact
							if (
								hiddenInputsArr != null &&
								typeof hiddenInputsArr != 'undefined'
							) {
								hiddenInputsArr.forEach(function (el, id) {
									var elName = el.getAttribute('name');
									if (elName != null && typeof elName != 'undefined') {
										if (elName == 'lot-reference') {
											el.value =
												'La reference du lot concerne est: ' +
												rep.message.numero;
										} else if (elName == 'lot-id') {
											el.value = rep.message.reference;
										} else if (elName == 'lot-price') {
											el.value = rep.message.prix;
										} else if (elName == 'lot-floor') {
											el.value = rep.message.etage;
										} else if (elName == 'lot-exposition') {
											el.value = rep.message.exposition;
										}
									}
								});
							}

							setTimeout(function () {
								detailsPanel.classList.remove('notloaded');
								detailsPanel.classList.add('loaded');
							}, 100);
						}
					} else {
						// add message error
						console.log(
							'fail | status : ' +
								this.status +
								' | response : ' +
								this.response
						);
					}
				};
				request.onerror = function () {
					console.log('connection error');
				};
				request.send();
				cleanPanel(detailsPanel, modelDetailsHtml, null);
			});
		});
	}

	// Remettre à l'état initial le panel %%
	function cleanPanel(item, model) {
		allPanels.forEach(function (element, id) {
			element.addEventListener('change', function (e) {
				if (e.target.id == 'close-all-panels') {
					if (item != null && typeof item != 'undefined' && model != null) {
						setTimeout(function () {
							item.innerHTML = model;
							item.classList.remove('loaded');
							item.classList.add('notloaded');
							if (
								hiddenInputsArr != null &&
								typeof hiddenInputsArr != 'undefined'
							) {
								hiddenInputsArr.forEach(function (el, id) {
									el.value = '';
								});
							}
						}, 500);
					}

					initCf7();
				}
			});
		});
	}

	// Remettre à l'état initial le panel %% avec multiHTML
	function cleanPanelMultiHtml(panel, label, multiHtml) {
		let closeLabel = document.querySelector('#close-all-panels');
		closeLabel.addEventListener('change', function (e) {
			if (multiHtml !== null) {
				var regexp = new RegExp(label, 'g');
				setTimeout(function () {
					multiHtml.forEach((element, i) => {
						element.innerHTML = element.innerHTML.replace(regexp, '%nom%');
					});
				}, 500);
			}

			initCf7();
		});
	}

	// récupérer tous les forms.sent.submitted - remove class
	function initCf7() {
		let cf7 = document.querySelectorAll('.wpcf7-form.submited.sent');
		[...cf7].forEach((form) => {
			form.classList.remove('sent');
			form.classList.remove('submited');
		});

		[...radioInput].forEach((input) => {
			input.checked = false;
		});
	}

	// Ajax Register
	if (registerForm != null && typeof registerForm != 'undefined') {
		registerForm.addEventListener('submit', function (e) {
			e.preventDefault();
			let civiliteRegister =
				registerForm.elements['civilite-inscription'].value;
			let prenomRegister = document.getElementById('prenom-inscription').value;
			let nomRegister = document.getElementById('nom-inscription').value;
			let emailRegister = document.getElementById('email-inscription').value;
			let telephoneRegister = document.getElementById(
				'telephone-inscription'
			).value;
			let passRegister = document.getElementById('pass-inscription').value;
			let confirmRegister = document.getElementById(
				'confirm-inscription'
			).value;
			let newsletterRegister =
				document.getElementById('newsletter-inscription').checked === true
					? '1'
					: '0';

			toggleLoader();

			let request = new XMLHttpRequest();
			request.open(
				'GET',
				`${FunctionsAjax.ajaxurl}?action=ajax_register&civilite-inscription=${civiliteRegister}&email-inscription=${emailRegister}&pass-inscription=${passRegister}&prenom-inscription=${prenomRegister}&nom-inscription=${nomRegister}&telephone-inscription=${telephoneRegister}&newsletter-inscription=${newsletterRegister}&confirm-inscription=${confirmRegister}&security=${FunctionsAjax.security}`,
				true
			);
			request.setRequestHeader(
				'Content-Type',
				'application/x-www-form-urlencoded; charset=UTF-8'
			);
			request.onload = function () {
				toggleLoader();
				cleanErrorMessages();

				if (200 === this.status) {
					let rep = JSON.parse(this.response);
					if (rep.type == 'success') {
						window.dataLayer.push({
							event: 'account-creation-success',
						});

						if (newsletterRegister == '1') {
							window.dataLayer.push({
								event: 'newsletter-success',
							});
						}

						handleSuccess(
							'Inscription réussie',
							'Vous allez être redirigé vers la page programme'
						);
					} else {
						handleErrors(rep, 'inscription');
					}
				} else {
					// add message error
					console.log(
						'fail | status : ' + this.status + ' | response : ' + this.response
					);
				}
			};
			request.onerror = function () {
				console.log('connection error');
			};
			request.send();
			console.log(request);
		});
	}

	// Ajax login
	if (loginForm != null && typeof loginForm != 'undefined') {
		loginForm.addEventListener('submit', function (e) {
			e.preventDefault();
			let emailLogin = encodeURIComponent(
				document.getElementById('email-connexion').value
			);
			let passwordLogin = document.getElementById('pass-connexion').value;

			toggleLoader();

			let request = new XMLHttpRequest();
			request.open(
				'GET',
				`${FunctionsAjax.ajaxurl}?action=ajax_login&email-connexion=${emailLogin}&pass-connexion=${passwordLogin}&security=${FunctionsAjax.security}`,
				true
			);
			request.setRequestHeader(
				'Content-Type',
				'application/x-www-form-urlencoded; charset=UTF-8'
			);
			request.onload = function () {
				cleanErrorMessages();
				toggleLoader();
				if (200 === this.status) {
					let rep = JSON.parse(this.response);
					if (rep.type == 'success') {
						if (FunctionsAjax.is_singular_programme.is_singular) {
							handleSuccess(
								'Connexion réussie',
								'Vous êtes maintenant connecté',
								''
							);
						} else {
							handleSuccess(
								'Connexion réussie',
								'Vous allez être redirigé vers votre espace',
								FunctionsAjax.is_singular_programme.url
							);
						}
					} else {
						handleErrors(rep, 'connexion');
					}
				} else {
					// add message error
					console.log(
						'fail | status : ' + this.status + ' | response : ' + this.response
					);
				}
			};
			request.onerror = function () {
				console.log('connection error');
			};
			request.send();
		});
	}

	// Ajax Patch
	if (infosForm != null && typeof infosForm != 'undefined') {
		infosForm.addEventListener('submit', function (e) {
			e.preventDefault();
			let civiliteRegister = infosForm.elements['civilite-informations'].value;
			let prenomRegister = document.getElementById('prenom-informations').value;
			let nomRegister = document.getElementById('nom-informations').value;
			let emailRegister = document.getElementById('email-informations').value;
			let telephoneRegister = document.getElementById(
				'telephone-informations'
			).value;
			let passRegister = document.getElementById('pass-informations').value;
			let confirmRegister = document.getElementById(
				'confirm-informations'
			).value;
			let newsletterRegister =
				document.getElementById('newsletter-informations').checked === true
					? '1'
					: '0';

			toggleLoader();

			let request = new XMLHttpRequest();
			request.open(
				'GET',
				`${FunctionsAjax.ajaxurl}?action=ajax_patch&civilite-informations=${civiliteRegister}&email-informations=${emailRegister}&pass-informations=${passRegister}&prenom-informations=${prenomRegister}&nom-informations=${nomRegister}&telephone-informations=${telephoneRegister}&newsletter-informations=${newsletterRegister}&confirm-informations=${confirmRegister}&security=${FunctionsAjax.security}`,
				true
			);
			request.setRequestHeader(
				'Content-Type',
				'application/x-www-form-urlencoded; charset=UTF-8'
			);
			request.onload = function () {
				toggleLoader();
				cleanErrorMessages();

				if (200 === this.status) {
					let rep = JSON.parse(this.response);
					if (rep.type == 'success') {
						handleSuccess(
							'Informations modifiées',
							'Vous avez modifié des informations sur votre compte Bâti-Armor '
						);
					} else {
						handleErrors(rep, 'informations');
					}
				} else {
					// add message error
					console.log(
						'fail | status : ' + this.status + ' | response : ' + this.response
					);
				}
			};
			request.onerror = function () {
				console.log('connection error');
			};
			request.send();
			console.log(request);
		});
	}

	// Ajax Password reset
	if (resetPasswordForm != null && typeof resetPasswordForm != 'undefined') {
		resetPasswordForm.addEventListener('submit', function (e) {
			e.preventDefault();
			let emailReset = document.getElementById('email-password-oublie').value;

			toggleLoader();

			let request = new XMLHttpRequest();
			request.open(
				'GET',
				`${FunctionsAjax.ajaxurl}?action=ajax_password_reset&email-password-oublie=${emailReset}&security=${FunctionsAjax.security}`,
				true
			);
			request.setRequestHeader(
				'Content-Type',
				'application/x-www-form-urlencoded; charset=UTF-8'
			);
			request.onload = function () {
				toggleLoader();
				cleanErrorMessages();

				if (200 === this.status) {
					let rep = JSON.parse(this.response);
					if (rep.type == 'success') {
						handleSuccess(
							'Email envoyé',
							'Vous allez recevoir un email pour réinitialiser votre mot de passe'
						);
					} else {
						handleErrors(rep, 'password-oublie');
					}
				} else {
					// add message error
					console.log(
						'fail | status : ' + this.status + ' | response : ' + this.response
					);
				}
			};
			request.onerror = function () {
				console.log('connection error');
			};
			request.send();
			console.log(request);
		});
	}

	// Ajax delete account
	if (deleteBtn != null && typeof deleteBtn != 'undefined') {
		deleteBtn.addEventListener('click', function (e) {
			e.preventDefault();
			let idUser = deleteBtn.dataset.id;

			let request = new XMLHttpRequest();
			toggleLoader();
			request.open('GET', `${FunctionsAjax.ajaxurl}?action=ajax_delete`, true);
			request.setRequestHeader(
				'Content-Type',
				'application/x-www-form-urlencoded; charset=UTF-8'
			);
			toggleLoader();
			request.onload = function () {
				if (200 === this.status) {
					let rep = JSON.parse(this.response);
					if (rep.type == 'success') {
						handleSuccess(
							'Compte supprimé',
							"Vous allez être redirigé sur la page d'accueil"
						);
					} else {
						handleErrors(
							rep,
							'Une erreur est survenue, veuillez réessayer plus tard.'
						);
					}
				} else {
					// add message error
					console.log(
						'fail | status : ' + this.status + ' | response : ' + this.response
					);
				}
			};
			request.onerror = function () {
				console.log('connection error');
			};
			request.send();
		});
	}

	// Load programs list zone
	// get searchform and search input #zone
	if (inputsSearchZone.length > 0) {
		inputsSearchZone.forEach((inputSearchZone) => {
			if (inputSearchZone != null && typeof inputSearchZone != 'undefined') {
				var datalist = inputSearchZone.nextElementSibling;
				// if user enter more than 3 characters in input search, wait 650ms before send request to wordpress ajax list_zones action
				inputSearchZone.addEventListener(
					'keyup',
					delay(function (e) {
						datalist.classList.remove('active');
						inputSearchZone.classList.remove('active');

						inputSearchZone.removeAttribute('title');

						if (inputSearchZone.value.trim().length < 2) {
							inputSearchZone.setAttribute(
								'title',
								'Veuillez entrer au moins 3 caractères'
							);
							return;
						}

						// do ajax request with field value and url = wordpress ajax list_zones action
						loadZoneDataList(
							`${FunctionsAjax.ajaxurl}?action=list_zones&zone=${inputSearchZone.value}`,
							inputSearchZone,
							datalist
						);
					}, 650)
				);

				// // on click on the input, add active class to the input
				inputSearchZone.addEventListener('click', function (e) {
					const form = inputSearchZone.closest('form');
					if (inputSearchZone.value.trim().length >= 3) {
						//trigger a keyup
						let event = new KeyboardEvent('keyup', {
							key: 'Enter',
							code: 'Enter',
							charCode: 13,
							keyCode: 13,
							view: window,
							bubbles: true,
						});
						inputSearchZone.dispatchEvent(event);
					} else {
						if (
							datalist != null &&
							typeof datalist != 'undefined' &&
							datalist.children.length == 0 &&
							!form.classList.contains('searchform--realisations')
						) {
							let lastSearchItem = getLastSearchItem();
							if (lastSearchItem !== null) {
								populateDatalist(lastSearchItem, datalist);
								datalist.classList.add('active');
								inputSearchZone.classList.add('active');
							}
						}
					}
				});

				//on click everywhere else than the datalist, hide it
				document.addEventListener('click', function (e) {
					if (e.target.name !== 'zone') {
						datalist.classList.remove('active');
						inputSearchZone.classList.remove('active');
					}
				});

				// if

				document.addEventListener('click', function (e) {
					if (e.target.className === 'searchform__datalist__item') {
						// fill inputSearchZone with the value of the clicked item
						const listItem = e.target;
						const form = listItem.closest('form');

						if (e.target.dataset.type == 'lastSearch') {
							let lastSearch = getLastSearch();
							inputSearchZone.value = e.target.dataset.name;
							let inputs = form.querySelectorAll('input, select');

							if (inputs.length > 0) {
								inputs.forEach((input) => {
									// if input name is a key in lastSearch, or a last search key is input.name + '[]', fill the input with the value of the key
									let searchKey = input.name;
									if (searchKey.includes('[]')) {
										searchKey = searchKey.replace('[]', '');
									}

									if (lastSearch.hasOwnProperty(searchKey)) {
										if (input.name.includes('[]')) {
											let values = lastSearch[searchKey];

											// check if input is select or checkbox
											if (input.tagName === 'SELECT') {
												// remove all selected options
												input.querySelectorAll('option').forEach((option) => {
													option.selected = false;
												});

												if (Array.isArray(values)) {
													values.forEach((value) => {
														let option = input.querySelector(
															`option[value="${value}"]`
														);
														if (option !== null) {
															option.selected = true;
														}
													});
												}
											} else if (input.type === 'checkbox') {
												// uncheck all checkbox
												input.checked = false;
												// if input value is in values, check it
												if (values.includes(input.value)) {
													input.checked = true;
												}
											}
										} else {
											input.value = lastSearch[searchKey];
										}
									} else {
										// manage multiple select
										if (input.tagName === 'SELECT') {
											input.querySelectorAll('option').forEach((option) => {
												option.selected = false;
											});
										} else if (input.type === 'checkbox') {
											input.checked = false;
										} else {
											input.value = '';
										}
									}
								});
							}
						} else {
							inputSearchZone.value = e.target.dataset.name;
							let inputType = form.querySelector(
								'input[type="hidden"][name="zone_type"]'
							);
							let inputID = form.querySelector(
								'input[type="hidden"][name="zone_id"]'
							);

							inputType.value = e.target.dataset.type;
							inputID.value = e.target.dataset.id;
						}
					}
				});
			}
		});
	}

	// format all input number with price in ther name in real time
	const inputsPrice = document.querySelectorAll('input[name*="price"]');
	if (inputsPrice.length > 0) {
		inputsPrice.forEach((inputPrice) => {
			inputPrice.addEventListener('input', function (e) {
				const cursorPosition = inputPrice.selectionStart; // Position actuelle du curseur
				const initialLength = inputPrice.value.length; // Longueur actuelle du champ
				let inputValue = removeFormat(inputPrice.value); // Retirer le format

				if (!isNaN(inputValue) && inputValue !== '') {
					inputPrice.value = formatEuro(parseInt(inputValue, 10));
				} else {
					inputPrice.value = ''; // Vider le champ si la valeur est invalide
				}

				// Repositionner le curseur
				const newLength = inputPrice.value.length; // Longueur après formatage
				inputPrice.setSelectionRange(
					cursorPosition + (newLength - initialLength),
					cursorPosition + (newLength - initialLength)
				);
			});
			document.addEventListener('DOMContentLoaded', function () {
				if (inputPrice.value != '') {
					inputPrice.value = formatEuro(parseInt(inputPrice.value, 10));
				}
			});
		});
	}

	// format all input number with surface in ther name in real time
	const inputsSurface = document.querySelectorAll('input[name*="surface"]');
	if (inputsSurface.length > 0) {
		inputsSurface.forEach((inputSurface) => {
			inputSurface.addEventListener('input', function (e) {
				const cursorPosition = inputSurface.selectionStart; // Position actuelle du curseur
				const initialLength = inputSurface.value.length; // Longueur actuelle du champ
				let inputValue = removeFormat(inputSurface.value); // Retirer le format

				if (!isNaN(inputValue) && inputValue !== '') {
					inputSurface.value = formatSurface(parseInt(inputValue, 10));
				} else {
					inputSurface.value = ''; // Vider le champ si la valeur est invalide
				}

				// Repositionner le curseur
				const newLength = inputSurface.value.length; // Longueur après formatage
				inputSurface.setSelectionRange(
					cursorPosition + (newLength - initialLength),
					cursorPosition + (newLength - initialLength)
				);
			});

			document.addEventListener('DOMContentLoaded', function () {
				if (inputSurface.value != '') {
					inputSurface.value = formatSurface(parseInt(inputSurface.value, 10));
				}
			});
		});
	}

	if (selectTypology != null && typeof selectTypology != 'undefined') {
		selectTypology.addEventListener('change', function (e) {});
	}

	const searchsSubmit = document.querySelectorAll('.searchform__submit');
	if (searchsSubmit.length > 0) {
		searchsSubmit.forEach((searchSubmit) => {
			let form = searchSubmit.closest('form');
			searchSubmit.addEventListener('click', function (e) {
				let zoneID = form.querySelector('input[name="zone_id"]');
				let zoneType = form.querySelector('input[name="zone_type"]');
				let zone = form.querySelector('input[name="zone"]');

				if ((zoneID.value == '' || zoneType.value == '') && zone.value != '') {
					e.preventDefault();
					alert(
						'Veuillez sélectionner une zone géographique dans la liste ou laisser ce champ vide'
					);
				}

				if(zone.value == '' && (zoneID.value != '' || zoneType.value != '')) {
					zoneID.value = '';
					zoneType.value = '';
				}

				// get all inputs with price and surface in their name
				const inputsPrice = document.querySelectorAll('input[name*="price"]');
				const inputsSurface = document.querySelectorAll(
					'input[name*="surface"]'
				);
				// for each input, remove the currency format and space
				inputsPrice.forEach((inputPrice) => {
					let value = inputPrice.value;
					value = value.replace(/\D/g, '');
					inputPrice.value = value;
				});
				inputsSurface.forEach((inputSurface) => {
					let value = inputSurface.value;
					value = value.replace(/\D/g, '');
					inputSurface.value = value;
				});
			});

			// prevent sending form with enter key if no zone is selected
			form.addEventListener('keypress', function (e) {
				if (e.key === 'Enter' && (zoneID.value == '' || zoneType.value == '')) {
					e.preventDefault();
				}
			});
		});
	}

	function removeFormat(value) {
		return value.replace(/\D/g, ''); // Supprime tout sauf les chiffres
	}

	function formatEuro(value) {
		const montantSansDecimales = Math.floor(value); // Supprime les décimales
		// Formatage avec l'affichage de ,00
		const formatter = new Intl.NumberFormat('fr-FR', {
			style: 'currency',
			currency: 'EUR',
			minimumFractionDigits: 0,
			maximumFractionDigits: 0,
		});
		return formatter.format(montantSansDecimales);
	}

	function formatSurface(value) {
		const surfaceSansDecimales = Math.floor(value); // Supprime les décimales
		// Formatage avec l'affichage de ,00
		const formatter = new Intl.NumberFormat('fr-FR', {
			minimumFractionDigits: 0,
			maximumFractionDigits: 0,
		});
		return formatter.format(surfaceSansDecimales) + ' m²';
	}

	function loadZoneDataList(url, input, list) {
		if (url != null) {
			let request = new XMLHttpRequest();

			list.classList.add('loading');
			input.classList.add('loading');
			const form = input.closest('form');
			request.open('GET', url, true);
			request.setRequestHeader(
				'Content-Type',
				'application/x-www-form-urlencoded; charset=UTF-8'
			);

			request.onload = function () {
				if (200 === this.status) {
					console.log(
						'success | status : ' +
							this.status +
							' | response : ' +
							this.response
					);
					let rep = JSON.parse(this.response);
					let lastSearch = [];
					if (!form.classList.contains('searchform--realisations')) {
						lastSearch = getLastSearchItem();
					}
					if (lastSearch === null) {
						lastSearch = [];
					}
					let data = [...lastSearch, ...rep.data];
					populateDatalist(data, list);
					list.classList.add('active');
					input.classList.add('active');
				} else {
					console.log(
						'fail | status : ' + this.status + ' | response : ' + this.response
					);
				}
				input.classList.remove('loading');
				list.classList.remove('loading');
			};

			request.onerror = function () {
				console.log('connection error');
			};

			// add loading class on request start

			request.send();
		}
	}

	function populateDatalist(data, list) {
		if (data === null || typeof data === undefined) {
			return;
		}
		var options = [];
		if (data.length > 0) {
			let type = '';
			// displayable values of type city, department and program
			let typeValues = {
				lastSearch: 'Votre dernière recherche',
				city: 'Villes',
				department: 'Départements',
				program: 'Programmes',
			};
			data.forEach((element) => {
				if (element.type != type) {
					options.push(
						`<li class="searchform__datalist__title">${
							typeValues[element.type]
						}</li>`
					);
					type = element.type;
				}
				if (element.type == 'lastSearch') {
					var lastSearch = getLastSearch();
					if (lastSearch !== null) {
						var labels = [];
						let filters = [
							'zone',
							'radius',
							'typology',
							'piece_number',
							'fiscal',
							'price_min',
							'price_max',
							'surface_min',
							'surface_max',
						];
						filters.forEach((filter) => {
							if (lastSearch.hasOwnProperty(filter) === false) {
								return;
							}

							// if last search [filter] is an array, join it with ','
							if (Array.isArray(lastSearch[filter])) {
								lastSearch[filter] = lastSearch[filter].join(', ');
							}

							if (filter == 'typology') {
								lastSearch[filter] =
									lastSearch[filter].charAt(0).toUpperCase() +
									lastSearch[filter].slice(1);
							}
							switch (filter) {
								case 'typology':
									lastSearch[filter] = 'Type : ' + lastSearch[filter];
									break;
								case 'piece_number':
									lastSearch[filter] = lastSearch[filter] + ' pièce(s)';
									break;
								case 'price_min':
									lastSearch[filter] = 'min : ' + lastSearch[filter] + ' €';
									break;
								case 'price_max':
									lastSearch[filter] = 'max : ' + lastSearch[filter] + ' €';
									break;
								case 'surface_min':
									lastSearch[filter] = 'min : ' + lastSearch[filter] + ' m²';
									break;
								case 'surface_max':
									lastSearch[filter] = 'max : ' + lastSearch[filter] + ' m²';
									break;
								case 'radius':
									lastSearch[filter] = '~ ' + lastSearch[filter] + ' km';
									break;
							}
							labels.push(lastSearch[filter]);
						});
					}
					let label = labels.join(' | ');
					options.push(
						`<li class="searchform__datalist__item" data-name="${element.name}" data-id="${element.id}" data-type="${element.type}">${label}</li>`
					);
				} else {
					options.push(
						`<li class="searchform__datalist__item" data-name="${element.name}" data-id="${element.id}" data-type="${element.type}">${element.name}</li>`
					);
				}
			});
		} else {
			options.push(`<li class="searchform
			__datalist__item" data-name="no-result" disabled>Aucun résultat</li>`);
		}
		list.innerHTML = options.join('');
	}

	function getLastSearch() {
		const data = sessionStorage.getItem('lastSearch');
		if (data !== null && typeof data !== undefined && data !== '{}') {
			return JSON.parse(data);
		}
		return null;
	}

	function getLastSearchItem() {
		let lastSearch = getLastSearch();
		if (lastSearch !== null && typeof lastSearch !== 'undefined') {
			return [
				{
					id: 0,
					type: 'lastSearch',
					name: 'Dernière recherche',
				},
			];
		} else {
			return null;
		}
	}
})();

// Utils
function delay(fn, ms) {
	let timer = 0;
	return function (...args) {
		clearTimeout(timer);
		timer = setTimeout(fn.bind(this, ...args), ms || 0);
	};
}

function toggleLoader() {
	let loaderBg = document.querySelectorAll('.loader-background');
	let bannerContent = document.querySelectorAll('.banner-content');
	for (let el of loaderBg) {
		el.classList.toggle('loader-active');
	}
	for (let el of bannerContent) {
		el.classList.toggle('banner-opacity');
	}
}

function cleanErrorMessages() {
	let prevConnexionSpan = document.querySelectorAll('.form-errors');
	if (prevConnexionSpan.length > 0) {
		for (let prev of prevConnexionSpan) {
			prev.remove();
		}
	}
}

function handleSuccess(titleText, messageText, url = '') {
	let toggleInput = document.getElementById('success-panel');
	let title = document.getElementById('confirmation-title');
	let message = document.getElementById('confirmation-message');
	title.innerText = titleText;
	message.innerText = messageText;
	toggleInput.checked = true;
	window.setTimeout(() => {
		toggleInput.checked = false;
		if (url !== '') {
			document.location.href = url;
		} else {
			document.location.reload(true);
		}
	}, 1700);
}

function handleErrors(rep, formName) {
	rep.type.forEach((type, index) => {
		let spanMessage = document.createElement('span');
		spanMessage.setAttribute('class', 'form-errors');
		let parent = '';
		if (rep['type'][index] == 'civilite') {
			parent = document.getElementsByClassName('civilite')[0];
		} else {
			parent = document.getElementById(rep['type'][index] + '-' + formName);
		}
		spanMessage.innerHTML = rep['message'][index];
		parent.after(spanMessage);
	});
}

// function sanitizeString(str) {
// 	var r = str.trim().toLowerCase();
// 	r = r.replace(new RegExp(/\s/g), '-');
// 	r = r.replace(new RegExp(/[àáâãäå]/g), 'a');
// 	r = r.replace(new RegExp(/æ/g), 'ae');
// 	r = r.replace(new RegExp(/ç/g), 'c');
// 	r = r.replace(new RegExp(/[èéêë]/g), 'e');
// 	r = r.replace(new RegExp(/[ìíîï]/g), 'i');
// 	r = r.replace(new RegExp(/ñ/g), 'n');
// 	r = r.replace(new RegExp(/[òóôõö]/g), 'o');
// 	r = r.replace(new RegExp(/œ/g), 'oe');
// 	r = r.replace(new RegExp(/[ùúûü]/g), 'u');
// 	r = r.replace(new RegExp(/[ýÿ]/g), 'y');
// 	r = r.replace(new RegExp(/\W/g), '-');
// 	return r;
// }
